// ClientDetails.tsx
import React, { useEffect, useState } from 'react';
import { ClientType, AdvisorType, AccessCodeData } from '../../store/slices/clientsSlice';
import useCustomStyles from '../../hooks/useCustomStyles';
import { accessCodeFormStyles } from '../../assets/styles/accessCodeFormStyles';
import { useDispatch, useSelector } from 'react-redux';
import { selectState } from '../../store/slices/themeSlice';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppDispatch, RootState } from '../../store/store';
//import { updateUserAction } from '../../store/actions/clientsActions';
import { customStyles } from '../../assets/styles/customStyles';
import CustomDisplayInput from '../../components/CustomDisplayInput';
import { capitalizeFirstLetter } from '../../utils';
import { ReactComponent as Trash} from '../../assets/images/Vector.svg'
import { ContractType } from '../../store/actions/accessCodeActions';
import { getContractByAccessCodeId } from '../../api';
import CustomButton from '../../components/CustomButton';
import { updateUsersAction } from '../../store/actions/clientsActions';

const AdvisorDetails: React.FC = () => {
  const { theme } = useSelector(selectState);
  const dispatch = useDispatch<AppDispatch>();
  const classes = useCustomStyles(accessCodeFormStyles, theme);
  const classes1 = useCustomStyles(customStyles, theme);
  const { clients, loading, error } = useSelector((state: RootState)=> state.clients_slice)
  const location = useLocation();
  const [advisor, setAdvisor ]= useState(location.state?.advisor as ClientType) ;
  const navigate = useNavigate();
  const [selectedClients, setSelectedClients] = useState<AdvisorType[]>([]);
  const [filteredClients, setFilteredClients] = useState<AdvisorType[] | undefined>();
  const [updatedAdvisor, setUpdatedAdvisor ]= useState(location.state?.advisor as ClientType) ;
  const [activeAccessCode, setActiveAccessCode]= useState<AccessCodeData | undefined>();
  const [contract, setContract] = useState<ContractType | undefined>();
  const [clientsToUnassign, setClientsToUnassign] = useState<Record<string, boolean>>(
    (advisor.clients || []).reduce((acc, client) => {
      acc[client.id] = false;
      return acc;
    }, {} as Record<string, boolean>)
  );
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const shouldShowButton = Object.values(clientsToUnassign).some(value => value);
    setShowButton(shouldShowButton);
  }, [clientsToUnassign]);

  const handleTrashChange = (clientId: string) => {
    setClientsToUnassign(prevState => ({ ...prevState, [clientId]: !prevState[clientId] }));
  };

  const handleSubmit =async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    console.log('clients to unassign', clientsToUnassign)
    // Filter out clients to be unassigned from the advisor's clients list
    const newClients = (advisor.clients || []).filter(client => !clientsToUnassign[client.id]);
  
    // Prepare updated advisor data with the new clients list
    const updatedAdvisorData = {
      ...advisor,
      clients: newClients,
    };
  
    // Prepare updated clients data from a main client list (assuming it's available in your state)
    // This example assumes you have a `clients` list from your state or props with full client details
    const updatedClientsData = (clients || [])
    .filter(client => clientsToUnassign[client.id]) // Only get clients to unassign
    .map(client => {
      console.log('inside', client);
      return {
        ...client,
        advisors: client.advisors?.filter(advisorData => advisorData.id !== advisor.id) || [],
      };
    }); 
    setUpdatedAdvisor(updatedAdvisorData)
     console.log('updated advisor and clinents',updatedAdvisorData, updatedClientsData)
    // Dispatch actions to update advisor and clients in your state
    // Example dispatch for updating both advisors and clients
    try{ 
      await dispatch(updateUsersAction([updatedAdvisorData, ...updatedClientsData])).unwrap();
     setClientsToUnassign((advisor.clients || []).reduce((acc, client) => {
      acc[client.id] = false;
      return acc;
    }, {} as Record<string, boolean>));
    }catch(e){
      console.log('error updating', e)
      setUpdatedAdvisor(location.state?.advisor as ClientType)
    }
    // Reset clientsToUnassign state
   
  };

  const handleUnassignAllClients = () => {
    // Step 1: Get the full client data for clients assigned to this advisor
    const clientsAssignedToAdvisor = advisor.clients?.map(client =>
      (clients || []).find(c => c.id === client.id)
    ).filter((client): client is ClientType => client !== undefined) || [];
  
    // Step 2: Remove the advisor from each client's advisors list
    const updatedClients = clientsAssignedToAdvisor.map(client => {
      return {
        ...client,
        advisors: client.advisors?.filter(advisorData => advisorData.id !== advisor.id) || [],
      };
    });
  
    // Step 3: Remove all clients from the advisor's clients list
    const updatedAdvisor = {
      ...advisor,
      clients: [],
    };
    console.log(' unassign all', updatedAdvisor, updatedClients)
  
    // Step 4: Dispatch the update for both the advisor and the affected clients
    dispatch(updateUsersAction([updatedAdvisor, ...updatedClients]))
      .unwrap()
      .then(() => {
        setAdvisor(updatedAdvisor); // Update local state to reflect changes
      })
      .catch((error) => {
        console.error("Error updating advisor and clients:", error);
      });
  };
  
  
  
  
  useEffect(()=>{
    if(!loading && !error){
      setAdvisor(updatedAdvisor);
      const clientAdvisorIds = advisor.clients?.map(item => item.id) || []
      setFilteredClients(clients?.filter(client => !clientAdvisorIds.includes(client.id)).map(item => {
        return  {id: item.id,
                 email: item.email, 
                 user_name: item.user_name, 
                 app_user_id: item.app_user_id}
                }))
    }
  },[loading])


  useEffect(()=>{
    console.log('advisor', advisor)
    if(!advisor){
        navigate(`/advisors`);
    }
  },[])

  useEffect(()=>{
    if(!filteredClients){
      const clientAdvisorIds = advisor.clients?.map(item => item.id) || []
      setFilteredClients(clients?.filter(client => !clientAdvisorIds.includes(client.id)).map(item => {
        return  {id: item.id,
                 email: item.email, 
                 user_name: item.user_name, 
                 app_user_id: item.app_user_id}
                }))
    }
    if(advisor && advisor.access_code_data){
      setActiveAccessCode(advisor.access_code_data.sort((a, b) => {
      return new Date(b.expire_at).getTime() - new Date(a.expire_at).getTime();
    })[0])
    }
  },[])

  const getClientContract= async(accessCodeId: string) => {
    try {
      const response = await getContractByAccessCodeId(accessCodeId)
      console.log('res contract', response)
      setContract(response)
    }catch (err){
      console.log('error', err);
    }
   
  }

  useEffect(()=>{
    if(activeAccessCode && activeAccessCode.id){
     getClientContract(activeAccessCode.id)
    }
  },[activeAccessCode])
  return (
    <div className={classes1.container} >
    <div className={classes1?.box}>
    <div className={classes1.tableContainer}>
      <h2 className={classes1.header}>ADVISOR DETAILS</h2>
    </div>
      <CustomDisplayInput 
        label={'Name'}
        name={'Name'} 
        value={advisor.user_name}
        disabled={true}
      />
       <CustomDisplayInput 
        label={'Email'}
        name={'Email'} 
        value={advisor.email}
        disabled={true}
       
      />

{contract &&
     <CustomDisplayInput 
        label={'Contract'}
        name={'Contract'} 
        value={contract.contract_ref && capitalizeFirstLetter(contract.contract_ref)}
        disabled={true}
      />}


       <CustomDisplayInput 
        label={'Subscription Expiry'}
        name={'Subscription-Expiry'} 
        value={advisor.access_code_data.sort((a, b) => {
          return new Date(b.expire_at).getTime() - new Date(a.expire_at).getTime();
        })[0].expire_at}
        disabled={true}
      />
 {advisor.roles && advisor.roles.map((role: string, index: number)=>
   <CustomDisplayInput 
      key = {`advisor-role-${index}`}
      label={index == 0? 'Roles' : ''}
      name={role} 
      value={ capitalizeFirstLetter(role)}
      disabled={true}
        />
 )
     
 } 
      {/* Conditionally render advisors or clients based on role_type */}

       {advisor.roles.includes('CRM') && advisor.clients && advisor.clients.length > 0 ? <div>
          <div className={classes1.flexContainer}><h3 className={classes1.label}>Clients Assigned</h3> </div>
          {advisor.clients && advisor.clients.map((client: AdvisorType) => (
            <CustomDisplayInput 
              key = {`advisor-${client.id}`} 
              name={`advisor-${client.id}`} 
              value={`${capitalizeFirstLetter(client.user_name)}(${client.email})`}
              icon = {<Trash width={20} height={20}/>}
              handleIconClick={()=> handleTrashChange(client.id)}
              disabled= {clientsToUnassign[client.id]}
            />
          ))}
          {showButton&&
                <CustomButton
                  title={'Save Changes'}
                  name="Assign-Advisors"
                  handleClick={e => handleSubmit(e)}
                  type="submit"
                  loading={loading}
                  disabled={loading}
                />}
                <CustomButton
                  title={'Unassign All Clients'}
                  name="unassignAll"
                  handleClick={handleUnassignAllClients}
                  type="submit"
                  loading={loading}
                  disabled={loading}
                />
        </div> : ''}
        
    </div>
    </div>
  );
};

export default AdvisorDetails;
