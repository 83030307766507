import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch, RootState } from '../../store/store';
import { accessCodesStyles } from '../../assets/styles/accessCodesStyles';
import {  getAccessCodes } from '../../store/actions/accessCodeActions';
import useCustomStyles from '../../hooks/useCustomStyles';
import {  useNavigate } from 'react-router-dom';
import CustomButton from '../../components/CustomButton';
import CustomTableRow from '../../components/CustomTableRow';
import { capitalizeFirstLetter, getCurrentDateFormatted } from '../../utils';


const AccessCodes: React.FC = () => {
  const theme = useSelector((state: RootState) => state.theme_slice.theme);
  const {user} = useSelector((state: RootState)=> state.auth_slice);
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>()
 // const { t } = useSelector(selectTranslationState);
  const { accessCodes } = useSelector((state: RootState) => state.accessCode_slice);
  const classes = useCustomStyles(accessCodesStyles, theme);

  useEffect(()=>{
    if( user && user.business){
      dispatch(getAccessCodes({business_client: user?.business.name ? user?.business?.id : ''}));
    }
  },[]) 
  
  const isExpired = (date: string) => {
    console.log('isExpired',getCurrentDateFormatted(), date )
    return getCurrentDateFormatted() > date
  }

  return (
    <div className={classes.container}>
      <div className={classes.tableContainer}>
        <h2 className={classes.header}>ASSIGN NEW LICENCES</h2>
      
       <div className={classes.buttonContainer} > 
      <CustomButton title={'Assign New Licence'} 
                    handleClick={()=> navigate('/generateLicence')} 
                    name={'Assign-New-Licence'} 
                    loading={false} /></div>
      </div>   
      <table className={classes.table}>
        <thead>
          <CustomTableRow key={`#index-thead`} 
            items={['Type','Licence Access Code',
            'Expiry Date','Status','']} 
            index={0} isHeader={true}/>
        </thead>
        <tbody>
                {accessCodes &&
            accessCodes.map((code, index) => (
              <CustomTableRow key={`#index-${index}`} items={[capitalizeFirstLetter(code.role_type),code.id, code.expire_at,isExpired(code.expire_at)? 'Expired': code.validated? 'Validated': 'Active']} index={index+1} link={`/accessCode/${code.id}`}/>
              
            ))}
        </tbody>
      </table>
     
    </div>
  );
};

export default AccessCodes;