import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AppDispatch, RootState } from "../store/store";
import { login } from "../store/actions/authActions";
import { selectState, setError } from "../store/slices/authSlice";
import { ReactComponent as LightLogoElisyan } from "../assets/images/light_big_logo_elisyan.svg";
import "../assets/styles/index.css";
import CustomButton from "../components/CustomButton";
import useCustomStyles from "../hooks/useCustomStyles";
import { customStyles } from "../assets/styles/login";
import { setTheme, ThemeName } from "../store/slices/themeSlice";
import LogInSignUpInput from "../components/SignInInput";
import { selectTranslationState } from "../store/slices/languageSlice";
import { ReactComponent as LightLogoSopher } from '../assets/images/LOGO Sopher light mode 1.svg';

function Login() {
  const {t} = useSelector(selectTranslationState)
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState<string | null>(null);
  const [passwordError, setPasswordError] = useState<string | null>(null);
  const { theme, activeTheme } = useSelector((state: RootState) => state.theme_slice);
  const { user } = useSelector((state: RootState) => state.auth_slice);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { error, loading, isAuthenticated } = useSelector(selectState);
  const classes = useCustomStyles(customStyles, theme);
  console.log("themefrom login ", theme.colorSchema.inputs.primary.background);
  const validate = () => {
    let isValid = true;

    if (!email) {
      setEmailError("Email is required.");
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      setEmailError("Please enter a valid email.");
      isValid = false;
    } else {
      setEmailError(null);
    }

    if (!password) {
      setPasswordError("Password is required.");
      isValid = false;
    } else if (password.length < 6) {
      setPasswordError("Password must be at least 6 characters.");
      isValid = false;
    } else {
      setPasswordError(null);
    }

    return isValid;
  };

  const handleLogin = async () => {
    if (validate()) {
      await dispatch(login({ email, password }));
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      console.log("isauthenticated", isAuthenticated);
      dispatch(
        setTheme({
          theme: (user?.business?.name && ["sopher", "sopher-test"].includes(user?.business?.name) )? user?.business?.name as ThemeName : "elisyan",
          mode: "light",
        })
      );
      navigate("/");
    }
  }, [isAuthenticated]);

  return (
    <div className={`${classes?.container} ${classes?.containerCenter}`}>
     {activeTheme == 'elisyan'? <LightLogoElisyan width={75} height={75} /> : <LightLogoSopher width={170} height={100} />} 
      <h2
        style={{
          // fontFamily: "Gilroy-SemiBold",
          fontSize: "22px",
          color: "#333333",
          fontWeight: "bold",
          // border: "1px solid #ccc",
          marginTop: 8,
          marginBottom: 6,
        }}
      >
        {t[activeTheme].loginScreen}
      </h2>
      <p
        style={{
          // fontFamily: "Gilroy-SemiBold",
          fontSize: "16px",
          color: "#333333",
          fontWeight: "normal",
          // border: "1px solid #ccc",
          marginTop: 2,
        }}
      >
        Log in to your account
      </p>
      <div>
        <LogInSignUpInput
          placeholder={"email"}
          value={email}
          onChangeText={value => {setEmail(value); setEmailError(null); dispatch(setError(undefined))}}
          // showToggle={true}
          // secureTextEntry={true}
        />
        {emailError && <p className={classes?.error}>{emailError}</p>}
        <LogInSignUpInput
          placeholder={"password"}
          value={password}
          onChangeText={value => {setPassword(value); setPasswordError(null); dispatch(setError(undefined))}}
          showToggle={true}
          secureTextEntry={true}
        />
        {passwordError && <p className={classes?.error}>{passwordError}</p>}
        <CustomButton
          title={loading ? "Logging in..." : "Login"}
          name="login"
          handleClick={handleLogin}
          disabled={false}
          loading={false}
        />
        <p style={{ textAlign: "center" }}>{t[activeTheme].loginScreenRegister}</p>{" "}
        <a
          href="https://elisyanwealth.com/contact-us/"
          target="_blank"
          rel="noopener noreferrer"
          style={{
            textAlign: "center",
            fontWeight: "bold",
            display: "block",
            textDecoration: "none",
            color: "inherit",
          }}
        >
          Contact Us
        </a>
        {error && <p className={classes?.error}>{error.toString()}</p>}
      </div>
    </div>
  );
}

export default Login;
