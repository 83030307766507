// src/assets/styles/AccessCodesStyles.ts

import { Theme } from '../../types';
import { CSSObject } from '@emotion/react';

export const customStyles = (theme: Theme): { [key: string]: Record<string, CSSObject | string | number> } => ({
    container: {
        padding: '5%',
       // width: '100%'
      },
      containerBox: {
        padding: '5%'
      },
      tableContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '5vh',
      },
      titleContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '1vh',
        marginTop: '1vh',
      },

      box: {
       width: '75%',
      },

      '@media (max-width: 900px)': {
        box: {
          width: '45%',
          margin: '5%'
        },
      },

      box2: {
        width: '45%',
        //margin: '5%'
      },
      header: {
        color: theme.colorSchema.colors.mainText,
        marginBottom: '20px',
        fontWeight: '500',
      },
      label: {
        marginBottom: '0.5rem',
        fontSize: '16px',
        color: theme.colorSchema.colors.mainText,
      },
      
      label2: {
        marginBottom: '0.5rem',
        fontSize: '16px',
        color: theme.colorSchema.colors.text,
      },
      
      flexContainer: {
        display: 'flex',
        justifyContent: 'space-between',
      },
      flexCenter: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-end',
      },
      flexEnd: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        width: '100%'
      },
      clickable: {
        cursor: 'pointer',
      },
      linkUnderline: {
        textDecoration: 'underline',
      },
      marginTop:{ 
        marginTop: '2rem'
      },
      flexColumn: {
        display: 'flex', 
        flexDirection: 'Column',
        width: '100%'
      },
      error: {
        color: "red",
        marginBottom: "10px",
        textAlign: "left",
      },
});