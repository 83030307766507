import { Theme } from "../../types";
import { CSSObject } from "@emotion/react";

export const customStyles = (
  theme: Theme
): { [key: string]: Record<string, CSSObject | string | number> } => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    alignContent: "center",
    
    minHeight: "100vh",
    padding: "20px",
    width: "300px",
    margin: "auto",
    maxHeight: "60%",
  },
  containerCenter: {
    justifyContent: "center",
  },
  containerMarginTop: {
    marginTop: '10vh',
  },
  title: {
    color: theme.colorSchema.colors.secondary,
    textAlign: "center",
    marginBottom: "5vh",
    fontWeight: "400",
  },
  input: {
    padding: "10px",
    marginBottom: "10px",
    borderRadius: "15px",
    border: "1px solid #ccc",
    width: "100%",
    boxSizing: "border-box",
  },
  error: {
    color: "red",
    marginBottom: "10px",
    textAlign: "left",
    width: "100%",
    maxWidth: "300px",
  },
});
