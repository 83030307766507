import React, { useEffect, useState } from 'react';
import { ClientType, AdvisorType, AccessCodeData } from '../../store/slices/clientsSlice';
import useCustomStyles from '../../hooks/useCustomStyles';
import { accessCodeFormStyles } from '../../assets/styles/accessCodeFormStyles';
import { useDispatch, useSelector } from 'react-redux';
import { selectState } from '../../store/slices/themeSlice';
import { useLocation } from 'react-router-dom';
import { AppDispatch, RootState } from '../../store/store';
import { updateUsersAction } from '../../store/actions/clientsActions';
import CustomButton from '../../components/CustomButton';
import CustomDisplayInput from '../../components/CustomDisplayInput';
import { customStyles } from '../../assets/styles/customStyles';
import { capitalizeFirstLetter } from '../../utils';
import { ReactComponent as Trash} from '../../assets/images/Vector.svg'
import { ContractType } from '../../store/actions/accessCodeActions';
import { getContractByAccessCodeId } from '../../api';
import CustomDisplay from '../../components/CustomDisplay';

const ClientDetails2: React.FC = () => {
  const { theme } = useSelector(selectState);
  const { advisors , loading, error} = useSelector((state: RootState)=> state.clients_slice)
  const classes = useCustomStyles(accessCodeFormStyles, theme);
  const classes1 = useCustomStyles(customStyles, theme);
  const location = useLocation();
  const [client, setClient] = useState(location.state?.client as ClientType);
  const [updatedClient, setUpdatedClient] = useState(location.state?.client as ClientType);
  const [selectedAdvisors, setSelectedAdvisors] = useState<AdvisorType[]>([]);
  const [filteredAdvisors, setFilteredAdvisors] = useState<AdvisorType[] | undefined>();
  const [showForm , setShowForm] = useState(false);
  const [activeAccessCode, setActiveAccessCode]= useState<AccessCodeData | undefined>();
  const [contract, setContract] = useState<ContractType | undefined>();
  const [showButton, setShowButton] = useState(false)
  const [toBeDelete , setToBeDelete] = useState((client.advisors || []).reduce((acc, advisor) => {
    acc[advisor.id] = false;
    return acc;
  }, {} as Record<string, boolean>));
  const dispatch = useDispatch<AppDispatch>();
   
  useEffect(() => {
    const show = Object.values(toBeDelete).some(value => value);
    setShowButton(show || selectedAdvisors.length > 0 || loading);
  }, [toBeDelete, selectedAdvisors]);

  useEffect(()=>{
    if(!loading && !error){
      setClient(updatedClient);
      const clientAdvisorIds = updatedClient.advisors?.map(item => item.id) || []
      setFilteredAdvisors(advisors?.filter(advisor => !clientAdvisorIds.includes(advisor.id))
      .map(item => {
        return  {id: item.id,
                 email: item.email, 
                 user_name: item.user_name, 
                 app_user_id: item.app_user_id}
                })
      )
      setSelectedAdvisors([]);
      setToBeDelete((client.advisors || []).reduce((acc, advisor) => {
        acc[advisor.id] = false;
        return acc;
      }, {} as Record<string, boolean>))
    }
  },[loading, error])

  const handleCheckboxChange = (advisor: AdvisorType) => {
    setSelectedAdvisors((prevSelected) =>
      prevSelected.includes(advisor)
        ? prevSelected.filter((id) => id !== advisor)
        : [...prevSelected, advisor]
    );
  };

  const handleTrashChange = (advisor: AdvisorType) => {
    setToBeDelete(prevState => ({ ...prevState, [advisor.id]: !prevState[advisor.id] }));
  };

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
  
    // Determine the final advisors list: add selected and remove toBeDelete
    const newAdvisors = (client.advisors || [])
      .filter(advisor => !toBeDelete[advisor.id])  // Remove advisors marked for deletion
      .concat(selectedAdvisors.filter(selected => 
        !client.advisors?.some(advisor => advisor.id === selected.id)
      ));  // Add new selected advisors
  
    // Update the client's advisor list
    setUpdatedClient(prevState => ({
      ...prevState,
      advisors: newAdvisors,
    }));
  
    const updatedAdvisors: ClientType[] = [];
  
    // Update selected advisors and remove current client from their list of clients
    selectedAdvisors.forEach(advisor => {
      const advisorObject = advisors?.find(item => item.id === advisor.id);
      if (advisorObject) {
        const newClients = [...(advisorObject.clients || [])];
  
        // Check if the current client is already in the advisor's clients list
        const isCurrentClientAssigned = newClients.some(clientItem => clientItem.id === client.id);
  
        // If the advisor is not already in the client's list, add the client
        if (!isCurrentClientAssigned) {
          newClients.push({
            id: client.id,
            email: client.email,
            user_name: client.user_name,
            app_user_id: client.app_user_id,
          });
        }
  
        updatedAdvisors.push({
          ...advisorObject,
          clients: newClients,
        });
      }
    });
  
    // Process advisors to be removed (marked in toBeDelete)
    Object.keys(toBeDelete).forEach(advisorId => {
      if (toBeDelete[advisorId]) { // If advisor is marked for deletion
        const advisorObject = advisors?.find(item => item.id === advisorId);
        if (advisorObject) {
          const newClients = (advisorObject.clients || []).filter(clientItem => 
            clientItem.id !== client.id // Remove the current client from this advisor's list
          );
  
          updatedAdvisors.push({
            ...advisorObject,
            clients: newClients,
          });
        }
      }
    });
  
    console.log('before submit updatedAdvisors and new advisors', updatedAdvisors, newAdvisors);
  
    // Dispatch the update action with the modified client and advisors
    dispatch(updateUsersAction([{ ...client, advisors: newAdvisors }, ...updatedAdvisors]));
  
    // Clear selected advisors after submission
    setSelectedAdvisors([]);
    // Reset toBeDelete state
    setToBeDelete((client.advisors || []).reduce((acc, advisor) => {
      acc[advisor.id] = false;
      return acc;
    }, {} as Record<string, boolean>));
  };
  
  useEffect(()=>{
    if(!filteredAdvisors){
      const clientAdvisorIds = client.advisors?.map(item => item.id) || []
      setFilteredAdvisors(advisors?.filter(advisor => !clientAdvisorIds.includes(advisor.id))
      .map(item => {
        return  {id: item.id,
                 email: item.email, 
                 user_name: item.user_name, 
                 app_user_id: item.app_user_id}
                })
                 )
    }
    if(client && client.access_code_data){
      setActiveAccessCode(client.access_code_data.sort((a, b) => {
      return new Date(b.expire_at).getTime() - new Date(a.expire_at).getTime();
    })[0])
    }
    
  },[])

  const getClientContract= async(accessCodeId: string) => {
    try {
      const response = await getContractByAccessCodeId(accessCodeId)
      console.log('res contract', response)
      setContract(response)
    }catch (err){
      console.log('error', err);
    }
   
  }

  useEffect(()=>{
    if(activeAccessCode && activeAccessCode.id){
     getClientContract(activeAccessCode.id)
    }
  },[activeAccessCode])
  
  return (
    <div className={classes1.container} >
      <div className={classes1?.box}>
      <div className={classes1.tableContainer}>
        <h2 className={classes1.header}>CLIENT DETAILS</h2>
      </div>
        <CustomDisplay
          label={'Name'}
          value={client.user_name}
          disabled={true}
        />
        <CustomDisplay
          label={'Email'}
          value={client.email}
          disabled={true}
        />
        {contract &&
          <CustomDisplay 
            label={'Contract'}
            value={contract.contract_ref && capitalizeFirstLetter(contract.contract_ref)}
            disabled={true}
          />}
        <CustomDisplay 
          label={'Subscription Expiry'}
          value={activeAccessCode?.expire_at}
          disabled={true}
        />
        <div>
          <div className={classes1.flexContainer}>
            <h3 className={classes1.label}>Advisors Assigned</h3>
            <p className={classes1.label} onClick={() => setShowForm(state => !state)}>+Add Advisor</p>
          </div>
          {client.advisors?.map((advisor: AdvisorType) => (
            <CustomDisplayInput 
              key = {`advisor-${advisor.id}`} 
              name={`advisor-${advisor.id}`} 
              value={`${capitalizeFirstLetter(advisor.user_name)}(${advisor.email})`}
              disabled= {toBeDelete[advisor.id]}
              icon={<Trash width={20} height={20}/>}
              handleIconClick={() => handleTrashChange(advisor)}
            />
          ))}
        </div>
        {showForm &&
          <div className={classes1.marginTop}>
            <form>
              <h3 className={classes1.label}>Available Advisors</h3>
              <div>
                {filteredAdvisors && filteredAdvisors.length > 0 ? filteredAdvisors.map((advisor) => (
                  <div key={advisor.id} style={{ marginBottom: '8px' }}>
                    <label>
                      <input
                        type="checkbox"
                        value={advisor.id}
                        checked={selectedAdvisors.includes(advisor)}
                        onChange={() => handleCheckboxChange(advisor)}
                      />
                      {advisor.user_name} ({advisor.email})
                    </label>
                  </div>
                )) : 'No Available Advisors!'}
              </div>
              
            </form>
          </div>
        }{showButton&&
                <CustomButton
                  title={'Save Changes'}
                  name="Assign-Advisors"
                  handleClick={e => handleSubmit(e)}
                  type="submit"
                  loading={loading}
                  disabled={loading}
                />}
      </div>
    </div>
  );
};

export default ClientDetails2;
