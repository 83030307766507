import { createAsyncThunk } from "@reduxjs/toolkit";
import { ClientType } from "../slices/clientsSlice";
import axios from "axios";


export const getClientsAction = createAsyncThunk<
  ClientType[] | undefined,
  {business_name:string | undefined}
>(
  'clients_slice/getClientsAction',
  async ({ business_name }, thunkAPI) => {
    try {
      console.log('inputs', business_name);
      //http://127.0.0.1:5001/elisyanwealth/us-central1/getClients
        const response = await axios.post('https://getclients-c5yieuvjca-uc.a.run.app', {business_name}, {
        headers: {
        'Content-Type': 'application/json',
        }});
        console.log('get clients list' , response)
        return response.data.data;
    } catch (e) {
      console.log('-------------- Error register User Device Token' + e);
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const updateUsersAction = createAsyncThunk<
  ClientType[] | undefined,
  ClientType[] | undefined
>(
  'clients_slice/updateUsersAction',
  async ( users , thunkAPI) => {
    try {
      console.log('update user', users);
      //http://127.0.0.1:5001/elisyanwealth/us-central1/updateUsers
        const response = await axios.post('https://updateusers-c5yieuvjca-uc.a.run.app', 
          { users: users}, {
        headers: {
        'Content-Type': 'application/json',
        }});
        console.log(' update user action response: ' , response)
        return users;
    } catch (e) {
      console.log('-------------- Error Update user' + e);
      return thunkAPI.rejectWithValue(e);
    }
  }
);


export const getAdvisorsAction = createAsyncThunk<
  ClientType[] | undefined,
  {business_name:string | undefined}
>(
  'clients_slice/getAdvisorsAction',
  async ({ business_name }, thunkAPI) => {
    try {
      console.log('inputs', business_name);
      //http://127.0.0.1:5001/elisyanwealth/us-central1/getAdvisors
        const response = await axios.post('https://getadvisors-c5yieuvjca-uc.a.run.app', {business_name}, {
        headers: {
        'Content-Type': 'application/json',
        }});
        console.log(' get clients list' , response)
        return response.data.data;
    } catch (e) {
      console.log('-------------- Error register User Device Token' + e);
      return thunkAPI.rejectWithValue(e);
    }
  }
);
