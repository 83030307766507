import { createAsyncThunk } from '@reduxjs/toolkit';
//import axios from 'axios';
import { firestore } from "../../Firebase";
import { addDoc, collection, doc, getDoc, getDocs, increment, query, serverTimestamp, Timestamp, updateDoc, where, writeBatch } from 'firebase/firestore';
import { ContractFormType } from '../../views/contracts';
import { toast } from 'react-toastify';
import { NavigateFunction } from 'react-router-dom';
/*
interface addContractArgs {
    business_client: string | undefined;
    contract_ref: string | undefined;
    useLimit: number | undefined;
    expire_at: string | undefined;
  }
interface AddContractResponse {
    id: string;
    business_client: string | undefined;
    contract_ref: string | undefined;
    useLimit: number | undefined;
    expire_at: string | undefined;
}
*/

// Define your types if needed for the form values
export interface ContractType {
    business_client: string | undefined;
    contract_ref: string | undefined;
    useLimit: number;
    expire_at: Date |string | undefined;
    id?: string | undefined
    licence_count: number;
  }


interface getBusinessesArgs {
  type: string | undefined;
}
export interface BusinessesType {
    id: string;
    type: string;
    name: string;
}  
  
  // Define the structure for an error response
  interface ErrorResponse {
    message: string;
    error: string;
  }
  

  export const getBusinesses = createAsyncThunk<
  BusinessesType[] | undefined,
  getBusinessesArgs
>(
  'accessCode_slice/getBusinesses',
  async ({ type }, thunkAPI) => {
    try {
      console.log('inputs', type);

      const businessesRef = collection(firestore, "businesses");
      let querySnapshot;

      if (type && type.length > 3) {
        const businessesQuery = query(businessesRef, where("type", "==", type));
        querySnapshot = await getDocs(businessesQuery);
      } else {
        querySnapshot = await getDocs(businessesRef);
      }

      const businesses: BusinessesType[] = querySnapshot.docs.map(doc => ({
        id: doc.id,
        type: doc.data().type ?? 'unknown', // Ensure the "type" field is always available
        name: doc.data().name ?? 'unknown' // Ensure the "name" field is always available
      }));

      console.log(businesses);
      return businesses;
      
    } catch (e) {
      console.log('-------------- Error register User Device Token' + e);
      return thunkAPI.rejectWithValue({
        message: 'Error retrieving businesses',
        error: e,
      } as ErrorResponse);
    }
  }
);

export const getContracts = createAsyncThunk<
ContractType[] | undefined,
{business_client : string}
>(
'accessCode_slice/getContracts',
async ({ business_client }, thunkAPI) => {
  try {
    

    const contractsRef = collection(firestore, "contracts");
    let querySnapshot;

    if (business_client && business_client.length > 3) {
      const businessDocRef = doc(firestore, `businesses/${business_client}`);
      const contractsQuery = query(contractsRef, where("owner", "==", businessDocRef));
      querySnapshot = await getDocs(contractsQuery);
    } else {
      querySnapshot = await getDocs(contractsRef);
    } 

    
    const contracts: ContractType[] = querySnapshot.docs.map(doc => ({
      business_client: doc.data().owner.id,
      contract_ref: doc.data().reference,
      useLimit: doc.data().use_limit,
      expire_at: doc.data().expire_at,
      licence_count: doc.data().licence_count,
      id: doc.id
    }));

    console.log(contracts);
    return contracts;
    
  } catch (e) {
    console.log('-------------- Error getContracts' + e);
    return thunkAPI.rejectWithValue({
      message: 'Error retrieving contracts',
      error: e,
    } as ErrorResponse);
  }
}
);

export const addContract = createAsyncThunk<
   ContractType | undefined,
   ContractFormType
>(
  'accessCode_slice/addContract',
  async ({  business_client,
    contract_ref,
    useLimit= 1,
    expire_at }, thunkAPI) => {
    try {
      console.log('inputs', business_client,
        contract_ref,
        useLimit,
        expire_at );

        const contractCollection = collection(firestore, 'contracts');
        console.log('collection:::::::::::::::', contractCollection);
        const ownerRef = doc(firestore, `businesses/${business_client}`);

       // Reference to the contracts collection
       const contractsRef = collection(firestore, 'contracts');
 
       // Create the contract document with the specified fields
       const newContract = {
         owner: ownerRef,  // Firestore document reference for owner
         reference: contract_ref,        // string field for reference
         use_limit: useLimit,  // number field for use limit
         expire_at: expire_at,  // Firestore Timestamp from Date object
         licence_count: 0
       };
 
       // Add the contract document to the collection
       const docRef = await addDoc(contractsRef, newContract);
       toast.success("Contract added successfully!");
       // Return the document ID of the newly created contract
       return {id: docRef.id , contract_ref, business_client, useLimit, expire_at , licence_count: 0};
      
    } catch (e) {
      console.log('-------------- Error adding new contract' + e);
      return thunkAPI.rejectWithValue({
        message: 'Error while adding a new contract',
        error: e,
      } as ErrorResponse);
    }
  }
);


interface FormRole  {
  label: string;
  value: string;
}
interface AccessCodeArgs {
  role_type: string;
  roles?: FormRole[];
  codeType: string;
  contract_ref?: string;
  business_client: string;
  email?: string[];
  expire_at?: string;
  number_of_codes_to_generate: number;
  navigate?: NavigateFunction; 
}

export interface AccessCodeType {
  id: string;
  role_type: string;
  roles?: string[];
  codeType: string;
  contract_ref?: string;
  business_client: string;
  for_business_client?: string;
  prefix?: string;
  email?: string[];
  expire_at: string;
  useLimit?: number;
  useCount?: number;
  validated: boolean;
  shared: boolean;
  created_at : Timestamp | Date | string;
  updated_at: Timestamp| Date | string;
}

export const addAccessCode = createAsyncThunk<
  AccessCodeType[] | undefined,  // Correctly defining return type as AccessCodeType[]
  AccessCodeArgs
>(
  'accessCode_slice/addAccessCode',
  async ({ navigate,role_type, roles, codeType, contract_ref, business_client, email, expire_at, number_of_codes_to_generate }, thunkAPI) => {
    try {
      console.log('inputs', role_type, roles, codeType, contract_ref, business_client, email, expire_at, number_of_codes_to_generate);

      const accessCodeCollection = collection(firestore, 'access_codes');
      console.log('collection:', accessCodeCollection);
      const contractRef = doc(firestore, `contracts/${contract_ref}`);
      const contractSnap = await getDoc(contractRef);
      let contract_expire_at: string = '';
      if (contractSnap.exists()) {
        const contractData = contractSnap.data();
        contract_expire_at = contractData.expire_at || '';
        console.log(contractData); // Access your fields from data object
      } else {
        console.log("No such document!");
      }
      const business_clientRef = doc(firestore, `businesses/${business_client}`)
      const newAccessCodes = [];  // Array to store the AccessCodeType objects
      const newAccessCodesids: string[] = []
      if (codeType === 'licence-code') {
        for (let i = 0; i < number_of_codes_to_generate; i++) {
          const newAccessCodeRef = doc(accessCodeCollection);  // Generate a new document reference
          const newCode = {
            role_type: role_type,
            roles: roles?.map(item => item.value),
            codeType,
            contract_ref: contractRef,  // Assuming contract_ref is a string path
            business_client: business_clientRef,
            email: email ?? [],  // Default to an empty array if undefined
            expire_at: expire_at ?? contract_expire_at ,  // Default to an empty string if undefined
            created_at: serverTimestamp() as unknown as Timestamp,
            updated_at: serverTimestamp() as unknown as Timestamp,
            validated: false,
            shared: false,
          };

          newAccessCodes.push(newCode);  // Add the new code to the array
          newAccessCodesids.push(newAccessCodeRef.id);
        }
      }

      // Initialize the batch
      const batch = writeBatch(firestore);

      // Add each access code to the batch
      newAccessCodes.forEach((accessCode, index) => {
        const newAccessCodeRef = doc(accessCodeCollection, newAccessCodesids[index]);  // Use the pre-generated ID
        batch.set(newAccessCodeRef, accessCode);  // Add the document to the batch
      });

      // Commit the batch write
      try {
        await batch.commit();
        const createdCodes: AccessCodeType[] = newAccessCodes.map((code, index)=> {
          return{...code ,
            business_client: code.business_client.path.split('/')[1] ,
            contract_ref: code.contract_ref.path.split('/')[1],
            id: newAccessCodesids[index], 
            created_at: new Date().toISOString().slice(0, 10) , 
            updated_at: new Date().toISOString().slice(0, 10)
          }})
        console.log('Batch commit successful', );
        // Update the contract to increase licence_count
        await updateDoc(contractRef, {
          licence_count: increment(number_of_codes_to_generate),  // Increase licence_count by the number of codes generated
        });
        console.log('incremented licence count in contract ' );
        toast.success("Access codes Created  successfully!");
        if(navigate){
          navigate(`../accessCode/${newAccessCodesids[0]}`)
        }
        
        return createdCodes
      } catch (error) {
        console.error('Batch write failed:', error);
        return thunkAPI.rejectWithValue({
          message: 'Batch write failed',
          error,
        });
      }
    } catch (e) {
      console.log('Error registering Access Codes:', e);
      return thunkAPI.rejectWithValue({
        message: 'Error registering Access Codes',
        error: e,
      });
    }
  }
);

export const addReferralCode = createAsyncThunk<
  AccessCodeType[] | undefined,
  { business_client: string;
    for_business_client: string;
    prefix?: string; 
    expire_at: string; 
    useLimit?: number; 
    number_of_codes_to_generate: number ;
    navigate?: (path: string) => void 
  }
>(
  'accessCode_slice/addReferralCode',
  async ({ 
    business_client, 
    expire_at, 
    for_business_client = 'elisyan',
    prefix,
    useLimit = 1,
    number_of_codes_to_generate = 1, 
    navigate }, 
    thunkAPI) => {
    try {
      console.log('Referral code inputs', business_client, expire_at, useLimit, for_business_client, prefix);

      const accessCodeCollection = collection(firestore, 'access_codes');
      const business_clientRef = doc(firestore, `businesses/${business_client}`);
      const newReferralCodes = [];
      const newReferralCodeIds: string[] = [];

      for (let i = 0; i < number_of_codes_to_generate; i++) {
        const newReferralCodeRef = doc(accessCodeCollection); // Generate a new document reference

        // Construct the new referral code
        const newCode = {
          id: newReferralCodeRef.id,
          role_type: "client", // Set role_type if needed, or remove if not applicable
          codeType: 'referral-code',
          business_client: business_clientRef,
          expire_at,
          for_business_client,
          prefix,
          useLimit:  Number(useLimit),
          useCount: 0,
          created_at: serverTimestamp() as unknown as Timestamp,
          updated_at: serverTimestamp() as unknown as Timestamp,
          validated: false,
          shared: false,
        };

        newReferralCodes.push(newCode);
        newReferralCodeIds.push(newReferralCodeRef.id);
      }

      // Initialize the batch
      const batch = writeBatch(firestore);

      // Add each referral code to the batch
      newReferralCodes.forEach((referralCode, index) => {
        const newReferralCodeRef = doc(accessCodeCollection, newReferralCodeIds[index]);
        batch.set(newReferralCodeRef, referralCode);
      });

      // Commit the batch write
      try {
        await batch.commit();

        const createdCodes: AccessCodeType[] = newReferralCodes.map((code, index) => ({
          ...code,
          id: newReferralCodeIds[index],
          business_client: code.business_client.path.split('/')[1],
          created_at: new Date().toISOString().slice(0, 10),
          updated_at: new Date().toISOString().slice(0, 10),
        }));

        console.log('Batch commit successful for referral codes');

        toast.success("Referral codes created successfully!");

        if (navigate) {
          navigate(`../accessCode/${newReferralCodeIds[0]}`);
        }

        return createdCodes;
      } catch (error) {
        console.error('Batch write failed:', error);
        return thunkAPI.rejectWithValue({
          message: 'Batch write failed',
          error,
        });
      }
    } catch (e) {
      console.log('Error creating referral codes:', e);
      return thunkAPI.rejectWithValue({
        message: 'Error creating referral codes',
        error: e,
      });
    }
  }
);


export const getAccessCodes = createAsyncThunk<
  AccessCodeType[] | undefined,
  { business_client?: string } // Make business_client optional
>(
  'accessCode_slice/getAccessCodes',
  async ({ business_client }, thunkAPI) => {
    try {
      console.log('business_client', business_client);

      const accessCodesRef = collection(firestore, "access_codes");
      let querySnapshot;

      // Check if business_client is provided and valid
      if (business_client && business_client.length > 2) {
        const businessDocRef = doc(firestore, `businesses/${business_client}`);
        console.log('busines ref', businessDocRef)
        const accessCodesQuery = query(accessCodesRef, where("business_client", "==", businessDocRef));
        querySnapshot = await getDocs(accessCodesQuery);
      } else {
        // If no business_client provided, return all access codes
        querySnapshot = await getDocs(accessCodesRef);
      }

      console.log('accessCodes in action ', querySnapshot.docs.map(item => item.data()));
      let data;
      const accessCodes: AccessCodeType[] = querySnapshot.docs.map(doc => {
        data = doc.data();
        return {
        id: doc.id,
        // Add your specific fields according to the access code structure
        role_type: data.role_type,
        roles: data.roles, // Default to an empty array if roles is undefined
        codeType: data.codeType,
        contract_ref: data.contract_ref? data.contract_ref.path.split('/')[1]: undefined,  // contract_ref is a string path or undefined
        useCount: data.useCount? data.useCount : 0,
        useLimit: data.useLimit? data.useLimit : 0,
        business_client: data.business_client.path.split('/')[1],  // Assuming business_client is also a string path
        for_business_client: data.for_business_client? data.for_business_client : undefined,
        email: data.email || [],  // Default to an empty array if undefined
        expire_at: data.expire_at || '',  // Default to an empty string if undefined
        created_at: data.created_at,//serverTimestamp() as unknown as Timestamp,
        updated_at: data.updated_at, //serverTimestamp() as unknown as Timestamp,
        validated: data.validated,
        shared: data.shared,
        // Add other fields as necessary
      }});

      console.log(accessCodes);
      return accessCodes;

    } catch (e) {
      console.log('-------------- Error getAccessCodes: ' + e);
      return thunkAPI.rejectWithValue({
        message: 'Error retrieving access codes',
        error: e,
      } as ErrorResponse);
    }
  }
);
